<template>
    <div class="container">
        <div class="col-12">
            <div class="row edit-row">
                <div class="editing-title">
                    <p>{{ $t('calendar.addOrUpdateQTY') }}</p>
                </div>
            </div>
            <div class="row edit-row">
                <div class="editing-wrapper">
                    <div class="col-12"></div>
                </div>
            </div>
            <div class="row edit-row">
                <div class="col-lg-6 col-12">
                    <button class=" save-button-wrapper popup-bookit-button w-100" type="button">
                        <p>{{ $t('calendar.add') }}</p>
                        <p style="margin: 0;">{{ $t('calendar.qty') }}</p>
                    </button>
                </div>
                <div class="col-lg-6 col-12">
                    <router-link :to="{ name: 'Calendar' }">
                        <button class="close-button-wrapper popup-bookit-button w-100" type="button">{{ $t('button.close') }}</button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
